@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add some custom Tailwind classes */
@layer components {
    .label {
        @apply font-bold text-xl;
    }
    .forminput {
        @apply rounded-sm border-2 border-gray-100 px-3 py-1 my-6 bg-white;
    }
}